
















































































import {Vue, Component, Prop, Watch} from "vue-property-decorator";
import GroupSucHeadType from "./indexType.";
import ScrollViewX from "@/components/ShareComponent/ScrollViewX.vue";
import { ImagePreview } from "vant"
import moment from "moment";
import {GroupSucStore} from "@/views/Group/GroupSuc/Store";
import {setColl} from "@/views/Group/Server";

@Component({name: "GroupSucHead",components:{ ScrollViewX }})
export default class GroupSucHead extends Vue implements GroupSucHeadType{
    DefaultIcon = require("@/assets/icon/Basics/default.png")
    RightIcon = require("@/assets/icon/Basics/Right.png")
    AddIcon = require("@/assets/icon/Basics/Add.png")
    CollIcon = require("@/views/Group/Img/coll.png")
    ACollIcon = require("@/views/Group/Img/A_coll.png")
    CommentIcon = require("@/views/Group/Img/comment.png")
    active = false

    handleClickPreview(index:number){
        ImagePreview({
            images:this.getImgList,
            startPosition:index,
            loop:true
        })
    }

    handleToPath(state:number){
        let commentData:any
        switch (state) {
            case 1:
                setColl(1,this.getData && this.getData.id).then(res=>{
                    this.active = !this.active
                })
                return;
            case 2:
                commentData = GroupSucStore.getGroupSucCommentData;
                commentData.toId = this.getData && this.getData.userId;
                commentData.firstCommentId = "";
                GroupSucStore.SetGroupSucCommentData( commentData )
                GroupSucStore.SetGroupSucCommentShow(true)
                return;
            default:
                this.$router.push({
                    path:"/joinList",
                    query:{
                        taskId:this.$route.query.taskId as string
                    }
                })
                return
        }
    }

    get getStateTxt(){
        switch ( this.getData && Number(this.getData.status) ) {
            case 0:
                return "加入"
            case 1:
                return "进行中"
            case 2:
                return "已结束"
            default:
                return "已结束"
        }
    }

    get getPhoto(){
        if ( this.getData  && this.getData.headImg ){
            return this.getData.headImg
        }else {
            return this.DefaultIcon
        }
    }

    get getStartTime(){
        if ( this.getData && this.getData.startDate ){
            return this.getData.startDate.split(" ")[0]
        }else{
            return moment().format("YYYY-MM-DD")
        }
    }

    get getTime(){
        if ( this.getData && this.getData.createTime ){
            return this.getData.createTime.split("T")[0]
        }else{
            return moment().format("YYYY-MM-DD")
        }
    }

    get getImgList(){
        if ( this.getData && this.getData.img ){
            return this.getData.img.split(",")
        }else{
            return []
        }
    }

    get getAddList(){
        return this.getData && this.getData.teamUserInfo || []
    }

    @Prop(Object)
    data!:any
    get getData(){ return this.data }
    @Watch("data")
    changeData(newVal:any){
        if ( newVal && newVal.isCollect == 1 ) this.active = true;
        else this.active = false;
    }
}
