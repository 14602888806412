





















import {Vue, Component, Prop, Watch} from "vue-property-decorator";
import GroupCommentType from "./indexType";
import {GroupSucStore} from "@/views/Group/GroupSuc/Store";
import {Toast} from "vant";
import {setComment} from "@/views/Group/Server";

@Component({name: "GroupComment"})
export default class GroupComment extends Vue implements GroupCommentType{
    show = false
    message = "";

    mounted(){
        this.show = this.getCommentShow
    }

    handleUpComment(): void {
        if ( this.message.length < 1 ){
            Toast("请输入评论内容")
            return
        }
        let commentData = GroupSucStore.getGroupSucCommentData;
        commentData.content = this.message
        GroupSucStore.SetGroupSucCommentData( commentData )
        setComment().then(res=>{
            Toast("评论成功")
            this.message = ""
            this.handleClose()
        })
    }

    handleClose(): void {
        GroupSucStore.SetGroupSucCommentShow( false )
    }

    @Prop(String)
    placeValue!:string
    get getPlaceValue(){
        return this.placeValue || '请输入评论内容'
    }

    @Watch("show")
    changeShow(newVal:boolean){
        if ( this.getCommentShow != newVal ){
            GroupSucStore.SetGroupSucCommentShow( newVal )
        }
    }

    get getCommentShow(){
        return GroupSucStore.getGroupSucCommentShow
    }
    @Watch("getCommentShow")
    changeCommentShow(newVal:boolean){
        this.show = newVal
    }
}
