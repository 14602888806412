










































import {Vue, Component, Prop, Emit} from "vue-property-decorator";
import CommentItemType from "./indexType";
import moment from "moment";
import Storage from "@/util/Storage"
import {setColl} from "@/views/Group/Server";
import {GroupSucStore} from "@/views/Group/GroupSuc/Store";
import {delComment} from "@/views/Group/GroupSuc/Server";

@Component({name: "CommentItem"})
export default class CommentItem extends Vue implements CommentItemType{
    collIcon = require("@/views/Group/Img/coll.png")
    A_collIcon = require("@/views/Group/Img/A_coll.png")
    DelIcon = require("@/views/Group/Img/del.png")
    CartIcon = require("@/views/Group/Img/comment.png")
    active = false
    userId = 0

    mounted(){
        this.userId = Storage.GetData_ && Storage.GetData_("userId")
    }

    activated(){
        this.userId = Storage.GetData_ && Storage.GetData_("userId")
    }

    handleClick(state:number){
        let taskId = ( this.$route.query.taskId || 0 ) as number
        let commentData:any;
        switch (state) {
            case 1: // 收藏
                setColl(1,taskId,this.getData.userId).then(res=>{
                    this.active = !this.active
                    this.getData.isCollect = this.active ? 1 : 0
                })
                return;
            case 2: // 评论
                commentData = GroupSucStore.getGroupSucCommentData;
                commentData.toId = this.getData && this.getData.userId || 0
                commentData.firstCommentId = this.getData && this.getData.id || 0
                GroupSucStore.SetGroupSucCommentData(commentData)
                GroupSucStore.SetGroupSucCommentShow(true)
                return;
            case 3: // 删除
                delComment(this.getData && this.getData.id).then(res=>{
                    // 删除成功
                    this.pullDel()
                })
                return;
            case 0: // 跳转
                this.$router.push({
                    path:"/secondComment",
                    query:{
                        taskId:(this.$route.query.taskId || 0) as string,
                        data:JSON.stringify(this.getData) || "{}"
                    }
                })
                return
        }
    }

    get getPhoto(){
        if ( this.getData && this.getData.heanImg ){
            return this.getData.heanImg
        }else{
            return require("@/assets/icon/Basics/default.png")
        }
    }

    get getTime(){
        if ( this.getData && this.getData.createTime ){
            return moment(this.getData.createTime).format("MM-DD HH:mm")
        }else{
            return moment().format("MM-DD HH:mm")
        }
    }

    get getActive(){
        if ( this.getData && this.getData.isCollect == 1 ){
            this.active = true
            return true
        }else{
            this.active = false
            return false
        }
    }

    @Prop(Object)
    data!:any
    get getData(){ return this.data }

    @Prop(Number)
    index!:number
    get getIndex(){ return this.index }

    @Emit("pullDel")
    pullDel(){
        return { index:this.index }
    }
}
