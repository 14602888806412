















import {Vue, Component, Watch} from "vue-property-decorator";
import GroupSucType from "./GroupSuc";
import ZoomPage from "../../../impView/PageSafety";
import HeadTop from "@/components/ShareComponent/HeadTop.vue";
import GroupSucHead from "@/views/Group/GroupSuc/components/Head/index.vue"
import GroupSucList from "@/views/Group/GroupSuc/components/List/index.vue";
import GroupSucState from "@/views/Group/GroupSuc/components/State/index.vue"
import GroupComment from "@/views/Group/components/Comment/index.vue";
import {getGroupSuc} from "@/views/Group/GroupSuc/Server";
import {GroupSucStore} from "@/views/Group/GroupSuc/Store";


@Component({name: "GroupSuc",components:{ HeadTop,GroupSucHead,GroupSucList,GroupSucState,GroupComment }})
export default class GroupSuc extends ZoomPage implements GroupSucType{
    data:any = {}

    mounted(){
        this.loadingShow = false
    }

    activated(){
        let upData = GroupSucStore.getGroupSucCommentData
        upData.taskId = this.$route.query.taskId as string
        GroupSucStore.SetGroupSucCommentData(upData)
        this.handleUpData()
    }

    handleUpData(){
        getGroupSuc(this.$route.query.taskId as string).then(res=>{
            this.data = res
        })
    }

    get getRefresh(){
        return GroupSucStore.getGroupSucRefresh
    }
    @Watch("getRefresh")
    changeRefresh(newVal:boolean){
        if ( newVal )this.handleUpData();
    }
}
