





















import {Vue, Component, Watch, Emit} from "vue-property-decorator";
import GroupSucListType from "./indexType";
import PullDownUpList from "@/components/ShareComponent/PullDownUp.vue"
import ListBottom from "@/components/ShareComponent/ListBottom.vue";
import {IndexStore} from "@/store/modules/Index";
import {GroupSucStore} from "@/views/Group/GroupSuc/Store";
import {getGroupComment} from "@/views/Group/GroupSuc/Server";
import CommentItem from "@/views/Group/GroupSuc/components/CommentItem/index.vue";
import GroupSucTitle from "@/views/Group/GroupSuc/components/Title/index.vue";

@Component({name: "GroupSucList",components:{ PullDownUpList,ListBottom,CommentItem,GroupSucTitle }})
export default class GroupSucList extends Vue implements GroupSucListType{
    Scroll = 0
    UpDownBool = false
    StopUp = false
    Forbid = true
    List:any[] = []
    scrollHeight = 0
    minHeight = 20
    app:any = {}
    commentNum = 0
    collectNum = 0

    activated(){
        let upData = GroupSucStore.getGroupSucCommentUpData
        upData.taskId = this.$route.query.taskId as string
        GroupSucStore.SetGroupSucCommentUpData( upData )
        this.init()
        if ( this.getList.length < 1 || (this.getList.length && this.getList[0]["taskId"] != this.$route.query["taskId"]) ){
            this.handleUpData(true)
        }
    }

    init(){
        let app:any = document.getElementById("app");
        this.app = app
        let boxHeight = app.offsetHeight,
            contentHeight = app.scrollHeight;
        if ( contentHeight > boxHeight ){
            this.scrollHeight = contentHeight - boxHeight
            app.addEventListener("scroll",this.handleScroll,{ passive: false })
        }else{
            app.removeEventListener("scroll",this.handleScroll,{ passive: false })
        }
    }

    handleFilterList(e:any){
        let { index = 0 } = e;
        let { List } = this;
        List.splice(index,1)
    }

    handleScroll(e:any){
        let { scrollTop } = e.target
        this.init()
        if ( scrollTop >= (this.scrollHeight - this.minHeight) ){
            this.Forbid = false
            this.app.scrollTop = scrollTop
            IndexStore.SetScrollStopBool(true)
        }else{
            this.Forbid = true
            IndexStore.SetScrollStopBool(false)
        }
        e.preventDefault()
        e.stopPropagation()
        return false
    }

    handleUpDown(): void {
        if ( this.StopUp )return
        let upData = GroupSucStore.getGroupSucCommentUpData
        upData.pageNo += 1
        GroupSucStore.SetGroupSucCommentUpData( upData )
        this.UpDownBool = true
        this.handleUpData()
    }

    handleUpData(bool=false): void {
        getGroupComment().then(res=>{
            this.UpDownBool = false
            this.commentNum = res.commentCount || 0
            this.collectNum = res.collectCount || 0
            if ( res.length < GroupSucStore.getGroupSucCommentUpData.pageSize )this.StopUp = true;
            if ( bool )
                GroupSucStore.SetGroupSucCommentList( res.moment );
            else
                GroupSucStore.SetGroupSucCommentList( GroupSucStore.getGroupSucCommentList.concat(res.moment) );
        })
    }

    get getList(){
        return GroupSucStore.getGroupSucCommentList
    }
    @Watch("getList")
    changeGetList(newVal:any[]){
        this.List = newVal
    }

    get getRefresh(){
        return GroupSucStore.getGroupSucCommentListRefresh
    }
    @Watch("getRefresh")
    changeRefresh(newVal:boolean){
        if ( newVal ){
            let commentUpData = GroupSucStore.getGroupSucCommentUpData;
            commentUpData.pageNo = 1
            GroupSucStore.SetGroupSucCommentUpData(commentUpData)
            this.UpDownBool = true
            this.handleUpData(true)
        }
    }

    @Emit("pullData")
    pullData(data:any){
        return data
    }
}
