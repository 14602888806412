import {
    DelComment,
    DelGroup,
    GroupComment,
    GroupSucData,
    JoinGroup,
    PayAfter,
    PayBefore,
    UpdateGroupState
} from "@/Api/Group";
import Storage from "@/util/Storage";
import {GroupSucStore} from "@/views/Group/GroupSuc/Store";
import {Toast} from "vant";
import router from "@/router";
declare const WeixinJSBridge: any;
declare const document:any;

// 详情
export function getGroupSuc(id:string|number) {
    const userId = Storage.GetData_ && Storage.GetData_("userId")
    const token = Storage.GetData_ && Storage.GetData_("token")
    return Promise.resolve( GroupSucData({userId,token},{ id }).then(res=>{
        return res.data || {}
    }) )
}

// 评论列表
export function getGroupComment() {
    const userId = Storage.GetData_ && Storage.GetData_("userId")
    const token = Storage.GetData_ && Storage.GetData_("token")
    return Promise.resolve(GroupComment({userId,token},GroupSucStore.getGroupSucCommentUpData).then(res=>{
        return res.data
    }))
}

// 删除评论
export function delComment(id:string|number) {
    const userId = Storage.GetData_ && Storage.GetData_("userId")
    const token = Storage.GetData_ && Storage.GetData_("token")
    return Promise.resolve( DelComment({userId,token},{ id }).then(res=>{
        Toast("删除成功")
        return res.data
    }) )
}

// 删除任务
export function delGroupSuc(id:string|number) {
    const userId = Storage.GetData_ && Storage.GetData_("userId")
    const token = Storage.GetData_ && Storage.GetData_("token")
    return Promise.resolve(DelGroup({userId,token},{ id }).then(res=>{
       return res.data
    }))
}

// 修改任务状态
export function updateGroupState<T=string|number>(id = 0,status = 0) {
    const userId = Storage.GetData_ && Storage.GetData_("userId")
    const token = Storage.GetData_ && Storage.GetData_("token")
    Toast.loading({ duration:0,message:"加载中~~" })
    return Promise.resolve(UpdateGroupState({userId,token},{ id,status }).then(res=>{
        Toast.clear()
        return res.data
    }))
}

// 支付成功回调
export function payBack(orderId:string) {
    const userId = Storage.GetData_ && Storage.GetData_("userId")
    const token = Storage.GetData_ && Storage.GetData_("token")
    return Promise.resolve(PayAfter({userId,token},{ orderId }).then(res=>{
        Toast.clear()
        GroupSucStore.SetGroupSucRefresh(true)
        GroupSucStore.SetGroupSucCommentListRefresh(true)
        return res.data
    }))
}

// 支付
function onBridgeReady(OrderData:any,fun:Function){
    ( "invoke" in WeixinJSBridge) && WeixinJSBridge.invoke(
        'getBrandWCPayRequest', {
            "appId":OrderData.appId,//公众号名称，由商户传入
            "timeStamp":OrderData.timeStamp,//时间戳，自1970年以来的秒数
            "nonceStr":OrderData.nonceStr, //随机串
            "package":OrderData.package,
            "signType":OrderData.signType,//微信签名方式：
            "paySign":OrderData.paySign //微信签名
        },
        (res:any)=>{
            if(res.err_msg == "get_brand_wcpay_request:ok" ){
                fun()
            }
        });
}
// 加入任务
export function addGroup(taskId = 0,money:string|number = 0) {
    const userId = Storage.GetData_ && Storage.GetData_("userId")
    const token = Storage.GetData_ && Storage.GetData_("token")
    const userInfo = JSON.parse(Storage.GetData_ && Storage.GetData_("UserData")||"{}")
    let OrderId = "";
    return new Promise((resolve, reject) => {
        Toast.loading({ message:"正在加入中~~",duration:0 })
        JoinGroup({userId,token},{ taskId,phone:userInfo && userInfo.phone || '' }).then(res=>{
            if ( money > 0 ) resolve(res.data);
            else reject()
        })
    }).then((res:any)=>{
        let { orderId = "",money = "" } = res
        OrderId = orderId
        let bool = navigator.userAgent.toLowerCase().match(/MicroMessenger/i)
        if ( bool != null && bool[0] != "micromessenger" ){
            router.push({
                path:"/goPay",
                query:{
                    orderId:OrderId,
                    state:"group",
                    money:money,
                    price:'0'
                }
            })
            return Promise.reject()
        }
        return PayBefore({userId,token},{ orderId }).then(res=>{
            return res.data
        })
    }).then(res=>{
        if( "WeixinJSBridge" in window  && typeof WeixinJSBridge == "undefined"){
            if( document.addEventListener ){
                document.addEventListener('WeixinJSBridgeReady', onBridgeReady.bind("_",res,payBack.bind("_",OrderId)), false);
            }else if ( "attachEvent" in document ){
                document.attachEvent('WeixinJSBridgeReady', onBridgeReady.bind("_",res,payBack.bind("_",OrderId)));
                document.attachEvent('onWeixinJSBridgeReady',onBridgeReady.bind("_",res,payBack.bind("_",OrderId)));
            }
        }else{
            onBridgeReady(res,payBack.bind("_",OrderId));
        }
    }).catch(cat=>{
        Toast.clear()
        GroupSucStore.SetGroupSucRefresh(true)
        GroupSucStore.SetGroupSucCommentListRefresh(true)
    })
}
