

















import {Vue, Component, Prop} from "vue-property-decorator";
import GroupSucTitleType from "./indexType";

@Component({name: "GroupSucTitle"})
export default class GroupSucTitle extends Vue implements GroupSucTitleType{

    activated(){
        let { state } = this.$route.query
    }

    @Prop([Number,String])
    collect!:string|number
    get getCollect(){ return this.collect || 0 }

    @Prop([Number,String])
    comment!:string|number
    get getComment(){ return this.comment || 0 }
}
