










































import {Vue, Component, Prop, Emit} from "vue-property-decorator";
import GroupSucStateType from "./indexType";
import {addGroup, delGroupSuc, updateGroupState} from "@/views/Group/GroupSuc/Server";

// 修改 任务状态 加入任务 删除任务 结束任务
@Component({name: "GroupSucState"})
export default class GroupSucState extends Vue implements GroupSucStateType{
    state = ""


    activated(){
        // hall 广场 myIssue 我发布 join 我参与
        let { state } = this.$route.query
        this.state = state as string
    }

    // 删除
    handleRemove(){
        delGroupSuc(this.$route.query.taskId as string).then(res=>{
            let time = setTimeout(()=>{
                let goNum = -1
                if ("update" in Object.keys(this.$route.query)) {
                    goNum = -2
                }
                this.$router.go(goNum)
                clearTimeout(time)
            })
        })
    }

    // 修改
    handleUpDate(){
        if ( !/update=true/.test(window.location.href) )window.location.replace(window.location.href+"&update=true");
        let time = setTimeout(()=>{
            this.$router.push({
                name:"issueGroup",
                query:{
                    state:"update",
                    data:JSON.stringify( this.getData && this.getData || {} )
                }
            })
            clearTimeout(time)
        },400)

    }

    // 加入任务
    handleJoin(){
        if ( this.getData && this.getData.status == 0 && this.getData.isJoinTask == 1 ) addGroup(( this.$route.query.taskId || 0 ) as number,this.getData.price || 0);

    }

    // 开始任务
    handleStartGroup(){
        updateGroupState(( this.$route.query.taskId || 0 ) as number,1).then(res=>{
            this.refresh()
        })
    }

    // 结束任务
    handleEndGroup(){
        updateGroupState(( this.$route.query.taskId ||0 ) as number,2).then(res=>{
            this.refresh()
        })
    }

    get getJoinBool(){
        if ( this.getData && this.getData.status == 0 ){
            if ( this.getData.isJoinTask == 1 ){
                return true
            }else{
                return false
            }
        }else{
            return false
        }
    }

    get getJoinBtnStr(){
        if ( this.getData && this.getData.status == 0 ){
            if ( this.getData.isJoinTask == 0 ){
                return "已加入"
            }else{
                return "加入任务"
            }
        }else if ( this.getData.isJoinTask == 1 ){
            if ( this.getData.isJoinTask == 1 ){
                return "未加入任务"
            }else{
                return "已加入"
            }
        }else{
            if ( this.getData.isJoinTask == 1 ){
                return "未加入任务"
            }else{
                return "已结束"
            }
        }
    }

    get getBtnStatus(){
        // getData && state != 'myIssue'
        if ( this.getData && this.getData.status < 2 && this.state != 'myIssue' ){
            return true
        }else if (this.getData.status >= 2){
            return true
        }else{
            return false
        }
    }

    get getColor(){
        switch ( this.getData && Number(this.getData.status) ){
            case 0:
                return "#fad435"
            case 1:
                return "#fad435"
            default:
                return "#f2f2f2"
        }
    }

    get getState(){
        switch ( this.getData && Number(this.getData.status) ){
            case 0:
                return "未加入"
            case 1:
                return "进行中"
            case 2:
                return "已结束"
            default:
                return "已结束"
        }
    }

    @Prop(Object)
    data!:any
    get getData(){ return this.data }

    @Emit("refresh")
    refresh(data?:any){
        return data
    }
}
